import Vue from 'vue'
import Router from 'vue-router'

// 引入一级组件
import DashBoard from './../views/dashboard/DashBoard'
// 懒加载
const Home = () => import('./../views/home/Home.vue');
const Category = () => import('./../views/category/Category.vue');
const Product = () => import('./../views/product/index.vue');
const ProductManagement = () => import('./../views/product/management.vue');
const ModifyProduct = () => import('./../views/product/modify.vue');
const AddProduct = () => import('./../views/product/add.vue');
const CategorySelect = () => import('./../components/categorySelect.vue');
const ProductSelect = () => import('./../components/productSelect.vue');
const BrandSelect = () => import('./../components/brandSelect.vue');
const Cart = () => import('./../views/product/modify.vue');
// 用户中心
const Mine = () => import('./../views/mine/Mine.vue');
const UserCenter = () => import('./../views/mine/children/UserCenter');
const MineOrder = () => import('./../views/mine/children/MineOrder');
// 引入订单组件
const Order = () => import('./../views/order/Order.vue')
const OrderDetail = () => import('./../views/order/children/OrderDetail.vue')
// 引入我的地址组件
const MyAddress = () => import('./../views/order/children/MyAddress.vue')
const EditAddress = () => import('./../views/order/children/children/EditAddress.vue')
const AddAddress = () => import('./../views/order/children/children/AddAddress.vue')
// 引入登录
const Login = () => import('./../views/login/Login.vue')
Vue.use(Router);

const router = new Router({
    routes: [
        { path: '/', redirect: '/login' },
        {
            path: '/dashboard',
            name: 'dashboard',
            component: DashBoard,
            children: [
                { path: '/dashboard', redirect: '/dashboard/mine' },
                // { path: 'home', name: 'home', component: Home, meta: { keepAlive: true } },
                {
                    path: 'mine',
                    name: 'mine',
                    component: Mine,
                    children: [
                        { path: 'userCenter', component: UserCenter }, // 用户中心
                        { path: 'mineOrder', component: MineOrder }, // 我的订单
                    ]
                }
            ]
        },
        {
            path: '/product',
            name: 'product',
            component: Product,
            children: [
                { path: 'category', name: 'category', component: Category, meta: { title: '商品查询', keepAlive: true } },
                { path: '/product', redirect: '/product/productManagement', meta: { title: '商品管理', keepAlive: true } },
                { path: 'productManagement', name: 'productManagement', meta: { title: '商品管理', keepAlive: true }, component: ProductManagement },
                {
                    path: 'modify/:id',
                    name: 'modify', meta: { title: '商品修改', keepAlive: true },
                    component: ModifyProduct,
                },
                {
                    path: 'add',
                    name: 'add', meta: { title: '商品新增', keepAlive: true },
                    component: AddProduct,
                },
                {
                    path: 'categorySelect',
                    name: 'categorySelect', meta: { title: '选择类目', keepAlive: true },
                    component: CategorySelect,
                },
                {
                    path: 'productSelect',
                    name: 'productSelect', meta: { title: '选择商品', keepAlive: true },
                    component: ProductSelect,
                },
                {
                    path: 'brandSelect',
                    name: 'brandSelect', meta: { title: '选择品牌', keepAlive: true },
                    component: BrandSelect,
                },
            ]
        },
        {
            path: '/confirmOrder',
            name: 'confirmOrder',
            component: Order,
            children: [
                {
                    path: 'myAddress',
                    name: 'myAddress',
                    component: MyAddress,
                    children: [
                        { path: 'addAddress', name: 'addAddress', component: AddAddress },
                        { path: 'editAddress', name: 'editAddress', component: EditAddress }
                    ]
                }, {
                    path: 'orderDetail',
                    name: 'orderDetail',
                    component: OrderDetail
                }
            ]
        },
        {
            path: '/login',
            name: 'login',
            component: Login
        }
    ]
})

export default router;

